<template>
  <PreviewLesson
    v-if="slideshow.id"
    :id="slideshow.id"
    title="שלב"
    :hide-outline="true"
    class="h-screen">
    <template #banner>
      <div
        class="flex justify-end px-10 pt-7 pb-5 text-white w-full absolute top-0 left-auto right-0 gap-4"
        :class="{ 'bg-white': firstSlide?.id != currentSlide?.id }"
        :style="{ 'z-index': 999999999 }">
        <BaseProgress
          v-if="progress"
          id="progress-bar"
          color="blue"
          :progress="progress"
          label-position="outside"
          :label-progress="false"></BaseProgress>
      </div>
    </template>
  </PreviewLesson>
</template>

<script setup lang="ts">
import {
  PreviewLesson,
  BaseProgress,
  createPresentation,
} from '@nwire/amit-design-system';
import { onMounted, onUnmounted, ref } from 'vue';
import { each, get, map } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import { submitSurvey } from '/@/services/students';
import { useAccountStore } from '/@/store/account';
import {
  fetchQuestionnaire,
  getQuestionnaireSubmissions,
} from '/@/services/surveys';

let style;
const progress = ref(0);
interface IBlock {
  id: string;
  type: string;
}
const getChoiceEnabled = state => {
  return state.length >= 1;
};

const getInitialBlockEnabled = () => {
  return true;
};

const getFreeTextEnabled = state => {
  return state.length >= 1;
};

const handlers = {
  choice: {
    initialValue: () => [],
    enabled: getChoiceEnabled,
  },
  free_text: {
    initialValue: () => '',
    enabled: getFreeTextEnabled,
  },
  initial_block: {
    initialValue: undefined,
    enabled: getInitialBlockEnabled,
  },
};

const canContinue = (blocks, states) => {
  let canContinue = true;
  each(states, (state, index) => {
    //get its name
    const block = blocks.find(block => block.id === index);
    if (!block) return canContinue;

    const handler: any = get(handlers, `${block.name}.enabled`, null);
    if (handler) {
      canContinue = canContinue && handler(state);
    }
  });
  return canContinue;
};

const toState = nextSlide => {
  const state = nextSlide.state || {};
  nextSlide.blocks.forEach(block => {
    const handler: Function = get(
      handlers,
      `${block.name}.initialValue`,
      () => [],
    );
    if (!state[block.id]) state[block.id] = handler(block);
  });

  return state;
};

const route = useRoute();
const router = useRouter();
const accountStore = useAccountStore();
const slideshow: any = ref({
  id: '',
  lesson: 'א',
  description: '',
  sections: [],
});

const firstSlide = ref();
const outline = ref([]);
let pages: any = [];
const localState: any = {};

const { currentSlide, onReady, onAction, onNext, onPrev, onFinish } =
  createPresentation({
    slideshow,
    outline,
  });

//TODO - use onSlide + use canContinue here instead
onReady(({ context }) => {
  context.setDisabled(false);
});

// every use interaction with page (blocks)
// next is disabled by default if page settings does not say other
// here we can enable next
onAction(({ context, event }) => {
  context.updateState(event.block.id, event.value);
  //
  let { state } = context;
  const contextState = state();

  let disabled = !canContinue(context.blocks(), contextState);
  context.setDisabled(disabled);
});

onPrev(async () => {
  progress.value = Math.max(progress.value - 100 / pages.length, 0);
});

onNext(async context => {
  if (context.get()) {
    context.next({});
    return;
  }

  let nextSlide = context.sync({});
  if (!nextSlide) return;
  progress.value = Math.min(progress.value + 100 / pages.length, 100);
  context.setLoading(false);
  localState[currentSlide.value.id] = context.state();
  let state = nextSlide.state;
  const nextSlideQuestion = nextSlide.blocks.find(
    block => block.type === 'question',
  );
  if (nextSlideQuestion) {
    state = toState(nextSlide);
  }
  let disabled = !canContinue(nextSlide.blocks, state);
  context.next({
    state,
    disabled,
  });
});

onFinish(async () => {
  const blocks = pages.flatMap(page => get(page, 'blocks', []));
  const records: any = [];
  map(localState, page => {
    for (const [key] of Object.entries(page)) {
      const currentBlock: IBlock = blocks.find(
        (block: IBlock) => block.id === key,
      )!;

      if (currentBlock && currentBlock.type === 'question') {
        records.push({
          question: currentBlock.id,
          answer: page[currentBlock.id],
        });
      }
    }
  });
  let data = {
    student: accountStore.user?.id,
    questionnaire: slideshow.value.id,
    survey: route.params.survey,
    records,
  };
  const { onSuccess, onError } = submitSurvey(data);
  onSuccess(() => onSuccessAction(true));

  onError(e => console.error(e));
});

const onSuccessAction = async jump => {
  if (jump) {
    style?.remove();
    return await router.push({
      name: route.query.finishedLesson ? 'course-home' : 'courses-intro',
      query: { finishedLesson: route.query.finishedLesson },
      params: {
        course: route.params.course,
      },
    });
  }
  fetchContent();
};

const fetchContent = () => {
  const { onSuccess } = fetchQuestionnaire(
    route.params.survey as string,
    route.params.questionnaire as string,
  );
  onSuccess(({ data: questionnaire }) => {
    style = document.createElement('style');
    style.innerHTML = questionnaire.display.customCss;
    document.head.appendChild(style);

    slideshow.value = questionnaire;
    firstSlide.value = get(questionnaire, 'sections.0.pages.0', null);
    pages = get(questionnaire, 'sections', []).flatMap(
      section => section.pages,
    );
  });
};

onMounted(() => {
  const { onSuccess } = getQuestionnaireSubmissions(
    route.params.survey as string,
    route.params.questionnaire as string,
    accountStore.user?.id,
  );
  onSuccess(({ data }) => onSuccessAction(data[0]?.records.length));
  fetchContent();
});

onUnmounted(() => {
  style?.remove();
});
</script>
